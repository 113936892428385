import {
  Points,
  PerspectiveCamera,
  Scene,
  PointsMaterial,
  WebGLRenderer,
  LoadingManager, Mesh, Vector3, BufferGeometry

} from 'three';

import {Tab} from "bootstrap";
//import {gsap} from 'gsap';
//import {Geometry} from "three/examples/jsm/deprecated/Geometry.js";
import {OBJLoader2} from "wwobjloader2/src/loaders/OBJLoader2";
import headObj from '../../obj/head.obj';

let sideWidth = window.innerWidth;
let sideHeight = window.innerHeight;

let Background = function () {

};

Background.headparticle = function () {
  //
  // if ( !Modernizr.webgl ) {
  // 	alert('Your browser dosent support WebGL');
  // }

  // let camera, scene, renderer;
  let mouseX = 0, mouseY = 0;
  let p;

  let windowHalfX = sideWidth / 2;
  let windowHalfY = sideHeight / 2;

  Background.camera = new PerspectiveCamera(35, sideWidth / sideHeight, 1, 2000);
  Background.camera.position.z = 300;

  // scene
  Background.scene = new Scene();

  // texture
  let manager = new LoadingManager();
  // manager.onProgress = function (item, loaded, total) {
  // 	//console.log('webgl, twice??');
  // 	console.log(item, loaded, total);
  // };


  // particles
  let p_geom = new BufferGeometry();
  let p_material = new PointsMaterial({
    color: 0xFFFFFF,
    size: 1.5
  });

  // model
  let loader = new OBJLoader2(manager);
  const points = [];
  loader.load(headObj, function (object) {
    // object.traverse(function (child) {
    //     if ((child as THREE.Mesh).isMesh) {
    //         (child as THREE.Mesh).material = material
    //     }
    // })
    object.traverse(function (child) {

      if (child instanceof Mesh) {

        // child.material.map = texture;

        let scale = 8;

        //var geometry = new THREE.Geometry().fromBufferGeometry( bufferGeometry );
        const position = child.geometry.attributes.position;
        //const vector = new THREE.Vector3();
        //console.log(position);
        for (let i = 0, l = position.count; i < l; i++)
          points.push(new Vector3(position.getX(i) * scale, position.getY(i) * scale, position.getZ(i) * scale));
      }
    });
    p_geom.setFromPoints(points);
  });

  p = new Points(
      p_geom,
      p_material
  );

  Background.scene.add(p);

  Background.renderer = new WebGLRenderer({alpha: true});
  Background.renderer.setSize(sideWidth, sideHeight);
  Background.renderer.setClearColor(0x000000, 0);



  document.querySelector('.particlehead').appendChild(Background.renderer.domElement);
  document.querySelector('.particlehead').addEventListener('mousemove', onDocumentMouseMove);


  window.addEventListener('resize', onWindowResize);

  function onWindowResize() {
    windowHalfX = sideWidth / 2;
    windowHalfY = sideHeight / 2;

    Background.camera.aspect = sideWidth / sideHeight;
    Background.camera.updateProjectionMatrix();

    Background.renderer.setSize(sideWidth, sideHeight);
  }

  function onDocumentMouseMove(event) {
    mouseX = (event.clientX - windowHalfX) / 2;
    mouseY = (event.clientY - windowHalfY) / 2;
  }

  Background.animate = function () {

    requestAnimationFrame( Background.animate );
    render();

  };



  function render() {
    Background.camera.position.x += ((mouseX * .5) - Background.camera.position.x) * .05;
    Background.camera.position.y += (-(mouseY * .5) - Background.camera.position.y) * .05;

    Background.camera.lookAt(Background.scene.position);
    //console.log(Background.scene.position);
    Background.renderer.render(Background.scene, Background.camera);

  }

  render();

  Background.animate();
};

const initTab = () => {
  let triggerTabList = [].slice.call(document.querySelectorAll('.nav-pills a'));
  triggerTabList.forEach(function (triggerEl) {
    let tabTrigger = new Tab(triggerEl);

    triggerEl.addEventListener('click', function (event) {
      event.preventDefault();
      tabTrigger.show();
    });
  });
};

export function BiPage() {
  if (document.querySelector('.bi-page')) {
    initTab();
    Background.headparticle();
  }
}
