import Swiper, {Virtual, Lazy, Pagination} from 'swiper';
Swiper.use([Virtual, Lazy, Pagination]);
import 'regenerator-runtime/runtime';

let latestNewsSlider = null;

const getLatestArticles = async (category, articles) => {
  // let params = {
  //   category: 'ecommerce',
  //   articles: [
  //     'vendere_on-line_e-commerce_magento_o_shopify',
  //     'certimeter_silver_partner_di_pimcore'
  //   ]
  // };

  let formData = new FormData();
  formData.append('category', category);
  formData.append('articles', articles);

  const response = await fetch(process.env.BE_TARGET+'/blogArticles', {
    method: 'POST',
    body: formData
  });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const result = await response.json();
  return result;
};

const initSlider = () => {
  const category = document.querySelector('.latest-news-swiper').getAttribute('data-category');
  const articles = document.querySelector('.latest-news-swiper').getAttribute('data-articles');
  latestNewsSlider = new Swiper('.latest-news-swiper', {
    //slidesPerView: 'auto',
    speed: 300,
    slidesPerView: 1,
    spaceBetween: 25,
    pagination: {
      el: '.latest-news .swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      992: {
        spaceBetween: 10,
        slidesPerView: 3.5,
      }
    },
    updateOnImagesReady: true,
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    // Enable lazy loading
    lazyLoading: true,
    virtual: true
  });
  getLatestArticles(category, articles).then((response) => {
    if (response.length) {
      buildSlides(response);
    }
  }).catch(error => {
    console.log(error.message);
    //buildSlides(ENV.articles);
  });
  //buildSlides(ENV.articles);
};

const buildSlides = (response) => {
  let slides = [];
  response.map(data => {
    let slide = `<div class="swiper-slide latest-news-slide"> <a rel="noopener" title="${data.title}" target="_blank" href="${data.url}" class="latest-news-content-wrapper"> <img class="img-fluid swiper-lazy" data-src="${data.thumbnailSmall}" alt="${data.title}" /> <div class="latest-news-content text-white"> <small>${data.category}</small> <div class="main-sub-title">${data.title}</div> <p class="text-truncate"><small>${data.subtitle}</small></p> <div class="latest-news-link-label">Scopri di più</div> </div> </a> </div>`;
    slides.push(slide);
  });
  latestNewsSlider.virtual.appendSlide(slides);
};

export function LatestNews() {
  if (document.querySelector('.latest-news-swiper')) {
    initSlider();
  }
}
