import {gsap} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import responsiveMediaMatch from "../utils/responsiveMediaMatch";
import {Tab} from "bootstrap";

gsap.registerPlugin(ScrollTrigger);


const introAnimation = () => {
  const introVideo = document.querySelector(".development-video-intro");
  const devIntro = document.querySelector(".development-intro");
  /* Encoding is important!!!
//--------------------------------
// ffmpeg settings used:
//--------------------------------

ffmpeg -i input.mp4 -vf scale=960:-1 -movflags faststart -vcodec libx264 -crf 20 -g 1 -pix_fmt yuv420p output.mp4

// scale filtergraph optional
// ffmpeg docs: http://ffmpeg.org/ffmpeg.html

*/


  document.querySelector('.development-intro-after-image').style.setProperty('--height', (devIntro.clientHeight) + 'px');

  introVideo.currentTime = 0;

  gsap.set(".development-intro-code-left", {translateX: -2000});
  gsap.set(".development-intro-code-right", {translateX: +2000});

  if (!responsiveMediaMatch.detectIsMobile()) {
    ScrollTrigger.create({
      trigger: introVideo,
      scrub: true,
      pin: introVideo,
      pinType: 'fixed',
      start: 'top top',
      end: 'bottom 50%+=100px',
      markers: false,
      onUpdate: function (self) {
        if (introVideo) {
          let scrollPos = self.progress;
          let videoDuration = introVideo.duration;
          let videoCurrentTime = videoDuration * scrollPos;

          if (videoCurrentTime) {
            introVideo.currentTime = videoCurrentTime;
          }

          // console.log(videoDuration, scrollPos, videoCurrentTime)
        }
      },
    });
    gsap.to('.hero-title', {
      opacity: 0,
      scrollTrigger: {
        trigger: '.development-intro-box',
        start: 'top top',
        end: '250',
        anticipatePin: true,
        pin: true,
        scrub: true,
        markers: false,
      }
    });

    gsap.fromTo(".development-intro-code-left", {translateX: -2000},
        {
          translateX: 0,
          scrollTrigger: {
            trigger: '.development-intro-code-left',
            start: 'top top',
            end: '250',
            scrub: true,
            pin: true,
            markers: false,
          }
        });
    gsap.fromTo(".development-intro-code-right", {translateX: +2000},
        {
          translateX: 0,
          scrollTrigger: {
            trigger: '.development-intro-code-right',
            start: 'top top',
            end: '250',
            scrub: true,
            pin: true,
            markers: false,
          }
        });
  }


};

const initTab = () => {
  let triggerTabList = [].slice.call(document.querySelectorAll('.nav-pills a'));
  triggerTabList.forEach(function (triggerEl) {
    let tabTrigger = new Tab(triggerEl);

    triggerEl.addEventListener('click', function (event) {
      event.preventDefault();
      tabTrigger.show();
    });
  });
};

export function DevelopmentPage() {
  const developmentPage = document.querySelector('.development-page');
  if (developmentPage) {
    initTab();
    introAnimation();
  }
}
