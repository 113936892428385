import {gsap} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const jobRequirementsAnimation = () => {
  let titleSectionHeightArray = [];
  const reqTitle = document.querySelectorAll('.job-requirements .job-requirements-title');
  const titleSection = document.querySelectorAll('.job-requirements .job-requirements-row');
  titleSection.forEach(section => {
    let t = section.querySelector('.job-requirements-title');
    titleSectionHeightArray.push((section.offsetHeight - 20) - t.clientHeight);
  });
  reqTitle.forEach((title, i) => {
    ScrollTrigger.create({
      trigger: title,
      start: 'top center',
      end: '+=' + titleSectionHeightArray[i],
      pin: true,
      pinType: "fixed",
      markers: false,
      pinSpacing: true
    });
  });
};

export function JobPage() {
  if (document.querySelector('.job-page')) {
    window.addEventListener('load', function () {
      if (window.innerWidth > 1140) {
        jobRequirementsAnimation();
      }
    });
  }
}
