import responsiveMediaMatch from "../utils/responsiveMediaMatch";


const setOpenPositionSectionHeight = () => {
  let openPositionSectionBg = document.querySelector('.open-position-list--bg');
  if (openPositionSectionBg) {
    openPositionSectionBg.style.setProperty('--open-position-height', window.innerWidth + 'px');
  }
};

const positionHover = () => {
  window.addEventListener('mousemove', mouseMoveHandler);
};

const mouseMoveHandler = (e) => {
  let left = e.clientX - 75 + "px";
  let top = e.clientY - 75 + "px";
  let isOpenPositionLink = e.target.classList.contains("open-position-link");
  if (isOpenPositionLink) {
    let linkImage = e.target.parentElement.querySelector('.open-position-image-inner');
    let linkText = e.target.parentElement.querySelector('.open-position-link-text');
    linkImage.style.top = top;
    linkImage.style.left = left;
    linkText.style.setProperty('--x', (top));
    linkText.style.setProperty('--y', (left));
  }
};

export function OpenPosition() {
  const openPositionSection = document.querySelector('.open-position-list');
  if (openPositionSection) {
    setOpenPositionSectionHeight();
    if (responsiveMediaMatch.detectIsDesktop()) {
      positionHover();
    }
  }
}
