import {Dropdown} from "bootstrap";
let isScrolling = false;
if (!Element.prototype.closest) {
	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
	}
	Element.prototype.closest = function (s) {
		const el = this;
		let ancestor = this;
		if (!document.documentElement.contains(el)) return null;
		do {
			if (ancestor.matches(s)) return ancestor;
			ancestor = ancestor.parentElement;
		} while (ancestor !== null);
		return null;
	};
}
const subMenuHandler = () => {
  let navbar = document.querySelector('.custom-navbar');
  let advanceSolutions = document.querySelector('.advance-solutions');
  let megaDropdown = document.querySelector('.custom-navbar .dropdown-mega .dropdown-toggle');
  let megaItemHoverable = document.querySelectorAll('.dropdown-mega-item--hover');
  let allServices = document.querySelectorAll('.solutions-services');
  megaItemHoverable.forEach(item => {
    item.addEventListener('mouseover', function () {
      item.closest('ul').classList.add('list-hovered');
      megaItemHoverable.forEach(mih => {
        mih.classList.remove('hovered');
      });
      allServices.forEach(as => {
        as.classList.add('d-none');
      });
      this.classList.add('hovered');
      let service = this.dataset.service;
      if (typeof service !== typeof undefined) {
        let inheritMenu = document.querySelector(`.${service}`);
        advanceSolutions.classList.add('d-none');
        inheritMenu.classList.remove('d-none');
        inheritMenu.style.display = 'block';
        inheritMenu.classList.add('open');

      }
    });
    // item.addEventListener('mouseout', function () {
    //   let service = this.dataset.service;
    //   if (typeof service !== typeof undefined) {
    //     let inheritMenu = document.querySelector(`.${service}`);
    //     advanceSolutions.classList.remove('d-none');
    //     inheritMenu.style.display = 'none';
    //   }
    // });
  });
  megaDropdown.addEventListener('show.bs.dropdown', function () {
    // console.log('mega menu open');
    navbar.classList.add('bg-white');
    navbar.classList.add('nav-menu-open');
  });
  megaDropdown.addEventListener('hide.bs.dropdown', function () {
    // console.log('mega menu closed');
    let lh = document.querySelector('.list-hovered');
    if (lh){
      lh.classList.remove('list-hovered');
    }
    advanceSolutions.classList.remove('d-none');
    allServices.forEach(t => {
      t.classList.add('d-none');
    });
    navbar.classList.remove('nav-menu-open');
    if (!isScrolling) {
      navbar.classList.remove('bg-white');
    }
  });
};


const scrollBackgroundTogller = () => {
  const navbar = document.querySelector(".custom-navbar");
  const navbarScroller = document.querySelector(".nav-scroller");
  window.addEventListener("scroll", function () {
    let scrollY = window.scrollY;
    isScrolling = scrollY > 0;
    navbar.classList.toggle("bg-white", scrollY > 0);
    if (navbarScroller){
      if (isScrolling)  {
        navbarScroller.classList.remove("d-none");
      } else {
        navbarScroller.classList.add("d-none");
      }
    }
  });
};

const initDropdown = () => {
  Array.from(document.querySelectorAll('.dropdown'))
      .forEach(ddNode => new Dropdown(ddNode));
};

export function Navbar() {
  if (document.querySelector('.custom-navbar')) {
    initDropdown();
    scrollBackgroundTogller();
    subMenuHandler();
  }
}
