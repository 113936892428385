import Swiper, {Autoplay, Grid, Pagination, Lazy} from 'swiper';

Swiper.use([Grid, Autoplay, Pagination, Lazy]);

export function ClientsAndPartners() {
  if (document.querySelector('.clients-swiper')) {
    new Swiper('.clients-swiper', {
      spaceBetween: 15,
      grid: {
        rows: 4,
        fill: 'row'
      },
      autoplay: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      preloadImages: false,
      // Enable lazy loading
      lazy: true,
      on: {
        beforeResize: function (self) {
          let vw = window.innerWidth;
          if (vw > 1140) {
            self.params.slidesPerView = 7;
            self.params.grid.rows = 7;
          } else {
            self.params.slidesPerView = 3;
            self.params.grid.rows = 4;
          }
          self.init();
        },
      },
    });
  }
}
