const detectIsMobile = () => {
	let match = window.matchMedia('(max-width: 720px)');
	return match.matches;
};

const detectIsTablet = () => {
	let match = window.matchMedia('(min-width: 720px) and (max-width: 960px)');
	return match.matches;
};

const detectIsDesktop = () => {
	let match = window.matchMedia('(min-width: 960px)');
	return match.matches;
};

export default {detectIsTablet, detectIsDesktop, detectIsMobile};
