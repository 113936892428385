import {gsap} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import responsiveMediaMatch from "../utils/responsiveMediaMatch";

gsap.registerPlugin(ScrollTrigger);


const progressToggler = (self) => {
  const lists = document.querySelectorAll('.nav-scroller [data-progress-tool]');
  lists.forEach(l => {
    l.classList.remove('active');
  });
  const list = document.querySelector('[data-progress-tool="' + self.trigger.dataset.servicePin + '"]');
  if (list !== null) {
    list.classList.add('active');
  }
};

const progressBar = (self) => {
  const list = document.querySelector('[data-progress-tool="' + self.trigger.dataset.servicePin + '"]');
  if (list !== null) {
    let progress = Math.round(self.progress*100);
    list.querySelector('div').style.width = progress+'%';
  }
};

const progressHandler = () => {
  const services = document.querySelectorAll('[data-service-pin]');
  window.addEventListener('load', function () {
    services.forEach(service => {
      ScrollTrigger.create({
        trigger: service,
        start: 'top center',
        end: 'bottom center',
        markers: false,
        onEnter: self => progressToggler(self),
        onEnterBack: self => progressToggler(self),
        onUpdate: self => progressBar(self)
      });
    });
  });
};

// const showHideProgressTool = () => {
//   gsap.set(progressTool, {translateX: -50});
//   gsap.fromTo(progressTool, {translateX: -50},
//       {
//         translateX: 0,
//         scrollTrigger: {
//           trigger: '.service-container',
//           start: 'top top',
//           end: 'bottom 50%+=100px',
//           scrub: 1,
//           markers: false,
//         }
//       });
// };


export function ProgressTool() {
  const progressTool = document.querySelector('.nav-scroller');
  if (progressTool && responsiveMediaMatch.detectIsDesktop() ||  responsiveMediaMatch.detectIsTablet()) {
    //showHideProgressTool();
    progressHandler();
  }


}
