import 'core-js/stable';
import './assets/styles/main.scss';
import {Form} from "./assets/scripts/components/Form";
import {Hero} from './assets/scripts/components/Hero';
import {Streams} from './assets/scripts/components/Streams';
import {CertificatesAndPartnership} from "./assets/scripts/components/CertificatesAndPartnership";
import {LatestNews} from "./assets/scripts/components/LatestNews";
import {Navbar} from "./assets/scripts/components/Navbar";
import {AboutUsPage} from "./assets/scripts/components/AboutUsPage";
import {OpenPosition} from "./assets/scripts/components/OpenPosition";
import {DevelopmentPage} from "./assets/scripts/components/DevelopmentPage";
import {InteractiveCitySelection} from "./assets/scripts/components/InteractiveCitySelection";
import {BiPage} from "./assets/scripts/components/BiPage";
import {SecurityPage} from "./assets/scripts/components/SecurityPage";
import {VideoFullScreen} from "./assets/scripts/components/VideoFullScreen";
import {JobPage} from "./assets/scripts/components/JobPage";
import {ClientsAndPartners} from "./assets/scripts/components/ClientsAndPartners";
import {ProgressTool} from "./assets/scripts/components/ProgressTool";
import {SocialLinkPinned} from "./assets/scripts/components/SocialLinkPinned";
import {ScrollTo} from "./assets/scripts/components/ScrollTo";
import {IeDetector} from "./assets/scripts/utils/IeDetector";
import {InsightSwiper} from "./assets/scripts/components/InsightSwiper";

// App code
IeDetector();
SocialLinkPinned();
ScrollTo();
Navbar();
ProgressTool();
JobPage();
ClientsAndPartners();
InteractiveCitySelection();
BiPage();
SecurityPage();
DevelopmentPage();
OpenPosition();
AboutUsPage();
Form();
Hero();
Streams();
CertificatesAndPartnership();
LatestNews();
VideoFullScreen();
InsightSwiper();