import Swiper, {Pagination, Navigation, Autoplay} from 'swiper';
import responsiveMediaMatch from "../utils/responsiveMediaMatch";

Swiper.use([Pagination, Navigation, Autoplay]);

const manageHeroVideo = (slide) => {
  const index_currentSlide = slide.realIndex;
  const currentSlide = slide.slides[index_currentSlide];
  const videoElem = currentSlide.querySelector('.hero-video');
  const allVideoElem = slide.el.querySelectorAll('.hero-video');
  if (videoElem && responsiveMediaMatch.detectIsDesktop()) {
    // console.log('play video', videoElem);
    videoElem.play();
  } else {
    if (allVideoElem.length) {
      allVideoElem.forEach((elem) => {
        // console.log(v);
        // console.log('pause video', elem);
        elem.pause();
      });
    }
  }
};

const swiper = () => {
  new Swiper(".hero", {
    pagination: {
      el: '.hero-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // autoplay: {
    //   delay: 5000
    // },
    slidesPerView: "auto",
    cssMode: true,
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    on: {
      init: (self) => {
        const index_currentSlide = self.realIndex;
        const currentSlide = self.slides[index_currentSlide];
        const videoElem = currentSlide.querySelector('.hero-video');
        if (videoElem && responsiveMediaMatch.detectIsMobile()) {
          videoElem.pause();
        }
      },
      slideChange: (self) => {
        manageHeroVideo(self);
      }
    }
  });
};

export function Hero() {
  if (document.querySelector('.hero')) {
    swiper();
  }
}
