import Swiper, {EffectFade, Lazy} from 'swiper';
import responsiveMediaMatch from "../utils/responsiveMediaMatch";
Swiper.use([EffectFade, Lazy]);

const adaptImage = (slide = false, isFirstLoad) => {
  const offset = 200;
  if (isFirstLoad) {
    const container = document.querySelectorAll('.certificates-and-partnership-container');
    container.forEach(c => {
      const cf = c.querySelector('.container-fluid');
      let image = c.querySelector('.certificates-and-partnership-bg');
      if (responsiveMediaMatch.detectIsMobile()) {
        image.style.setProperty('--height', (cf.offsetHeight + (offset / 2)+50) + 'px');
      } else {
        image.style.setProperty('--height', (cf.offsetHeight + (offset)+50) + 'px');
      }
    });
  }
  if (slide) {
    const index_currentSlide = slide.realIndex;
    const currentSlide = slide.slides[index_currentSlide];
    const currentImage = currentSlide.querySelector('.certificates-and-partnership-bg');
    const container = currentSlide.querySelector('.certificates-and-partnership-content>.container-fluid');
    let i = document.querySelectorAll('.certificates-and-partnership-bg');
    if (!responsiveMediaMatch.detectIsMobile()) {
      i.forEach(img => {
        img.style.setProperty('--height', (container.offsetHeight + (offset / 2)+50) + 'px');
      });
      currentImage.style.setProperty('--height', (container.offsetHeight + (offset / 2)+50) + 'px');
    } else {

      i.forEach(img => {
        img.style.setProperty('--height', (container.offsetHeight + (offset / 2)+50) + 'px');
      });
    }
  }
};

const certificateAndPartnershipSwiper = new Swiper(".certificates-and-partnership-swiper", {
  allowTouchMove: false,
  effect: 'fade',
  updateOnImagesReady: true,
  preloadImages: false,
  lazy: true,
  fadeEffect: {
    crossFade: true
  },
  on: {
    slideChange: (slide) => {
      adaptImage(slide, false);
    },
    init: () => {
      adaptImage(false, true);
    },
  }
});

const handleSwiperPaginationElements = () => {
  const navElem = document.querySelectorAll('.certificates-and-partnership-pagination li');
  if (navElem.length) {
    navElem.forEach((elem) => {
      elem.addEventListener('click', function () {
        document.querySelector('.certificates-and-partnership-pagination .certificates-and-partnership-pagination--active').classList.remove('certificates-and-partnership-pagination--active');
        certificateAndPartnershipSwiper.slideTo(this.getAttribute('data-index'), 0);
        this.classList.add('certificates-and-partnership-pagination--active');
      });
    });
  }
};

export function CertificatesAndPartnership() {
  window.addEventListener('load', function () {
    if (document.querySelector('.certificates-and-partnership-swiper')) {
      certificateAndPartnershipSwiper.init();
      handleSwiperPaginationElements();
    }
  });
}
