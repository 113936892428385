import Swiper, {Pagination, Lazy, Grid} from 'swiper';

Swiper.use([Pagination, Lazy, Grid]);

export function Streams() {
  const streams = document.querySelector(".streams-swiper");
  if (streams){
    let streamsOption;
    if (streams.dataset.grid === 'row') {
      streamsOption = {
        spaceBetween: 25,
        // updateOnImagesReady: true,
        // preloadImages: false,
        // // Enable lazy loading
        // lazy: true,
        // // Enable lazy loading
        // lazyLoading: true,
        grid: {
          rows: 3,
          fill: 'row'
        },
        on: {
          beforeResize: function () {
            let vw = window.innerWidth;
            if (vw < 1140) {
              streamsSwiper.params.slidesPerView = 1;
              streamsSwiper.params.grid.rows = 3;
            } else {
              streamsSwiper.params.slidesPerView = 3;
              streamsSwiper.params.grid.rows = 1;
            }
            streamsSwiper.init();
          },
        }
      };
    } else {
      streamsOption = {
        slidesPerView: 1.2,
        spaceBetween: 25,
        updateOnImagesReady: true,
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        // Enable lazy loading
        lazyLoading: true,
        breakpoints: {
          992: {
            slidesPerView: 3,
            spaceBetween: 25,
          }
        }
      };
    }
    const streamsSwiper = new Swiper(".streams-swiper", streamsOption);
    streamsSwiper.init();
  }
}
