import 'regenerator-runtime/runtime';
import {load} from 'recaptcha-v3';
import TomSelect from "tom-select/dist/esm/tom-select";
import ENV from '../global';

const dynamicSelects = [];

if (!Element.prototype.closest) {
	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
	}
	Element.prototype.closest = function (s) {
		const el = this;
		let ancestor = this;
		if (!document.documentElement.contains(el)) return null;
		do {
			if (ancestor.matches(s)) return ancestor;
			ancestor = ancestor.parentElement;
		} while (ancestor !== null);
		return null;
	};
}

const handleMessageButton = (form, index) => {
  const reload = form.closest('.form-container').querySelector('.form-reload');
  const retry = form.closest('.form-container').querySelector('.form-retry');
  reload.addEventListener('click', () => {
    location.reload();
    return false;
  });
  retry.addEventListener('click', () => {
    hideErrorMessage(form);
    resetForm(form);
    makeLoading(false, form, index);
    return false;
  });
};

const resetForm = (form) => {
  form.reset();
};


const submitForm = async (form, index) => {
  const data = new FormData(form);
  console.log(form);
  makeLoading(true, form, index);
  const response = await fetch(process.env.BE_TARGET+'/jobForm', {
    method: 'POST',
    body: data,
    mode: 'no-cors'
  });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const result = await response.json();
  return result;
};

const submitContactForm = async (form, index) => {
  const data = new FormData(form);
  console.log(data);
  makeLoading(true, form, index);
  const response = await fetch(process.env.BE_TARGET+'/contactForm', {
    method: 'POST',
    body: data,
    mode: 'no-cors'
  });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const result = await response.json();
  return result;
};

const tomSelectCollectorAndHandler = (form) => {
  const ts = form.querySelectorAll('.ts-select');
  if (ts) {
    let ds = [];
    ts.forEach(select => {
      //console.log(form, TomSelect, select);
      ds[select.getAttribute('name')] = new TomSelect(select, {});
    });
    dynamicSelects.push(ds);
  }
};

const makeLoading = (loading, form, index) => {
  //const fieldset = form.querySelector('fieldset');
  const submitBtn = form.querySelector('.btn[type="submit"]');
  const labelToHide = submitBtn.querySelector('.hide-on-loading');
  const spinner = submitBtn.querySelector('.spinner-border');
  const loader = submitBtn.querySelector('.on-loading');
  if (loading) {
    dynamicSelects[index].forEach(select => {
      select.disable();
    });
    form.querySelectorAll('input').forEach(input => {
      input.disabled = true;
    });
    form.querySelectorAll('textarea').forEach(ta => {
      ta.disabled = true;
    });
    form.classList.add('disabled');
    //submitBtn.disabled = true;
    labelToHide.classList.add('d-none');
    spinner.classList.remove('d-none');
    loader.classList.remove('d-none');
  } else {
    dynamicSelects[index].forEach(select => {
      select.enable();
    });
    form.querySelectorAll('input').forEach(input => {
      input.disabled = false;
    });
    form.querySelectorAll('textarea').forEach(ta => {
      ta.disabled = false;
    });
    form.classList.remove('disabled');
    //submitBtn.disabled = false;
    labelToHide.classList.remove('d-none');
    spinner.classList.add('d-none');
    loader.classList.add('d-none');
  }
};

const validateTomSelect = (index) => {
  if (dynamicSelects.length) {
    dynamicSelects[index].forEach(curSel => {
      curSel.wrapper.classList.toggle('is-invalid', !curSel.isValid);
      curSel.wrapper.classList.toggle('is-invalid', !curSel.isValid);
    });
  }
};

const tomSelectHandler = () => {
  if (dynamicSelects.length) {
    dynamicSelects.forEach(ds => {
      ds.forEach(tomDs => {
        tomDs.on("change", () => {
          tomDs.wrapper.classList.toggle('is-invalid', !tomDs.isValid);
        });
      });
    });
  }
};

const showSuccessMessage = (form) => {
  const messageBox = form.closest('.form-container').querySelector('.alert-success-alt');
  messageBox.classList.remove('d-none');
  form.classList.add('d-none');
};

const showErrorMessage = (form, message) => {
  const messageBox = form.closest('.form-container').querySelector('.alert-danger-alt');
  messageBox.classList.remove('d-none');
  messageBox.querySelector('p').innerHTML = message;
  form.classList.add('d-none');
};
const hideErrorMessage = (form) => {
  const messageBox = form.closest('.form-container').querySelector('.alert-danger-alt');
  messageBox.classList.add('d-none');
  form.classList.remove('d-none');
};

const captcha = async (form) => {
  if (ENV.enableCaptcha) {
    let recaptcha = await load(ENV.capthaKey);
    form.querySelector('[name="gRecaptchaResponse"]').value = await recaptcha.execute('validate_captcha');
  }
};

const formSubmitAndValidationHandler = (forms) => {
  Array.prototype.slice.call(forms)
      .forEach(function (form, index) {
        captcha(form).catch(error => {
          console.log(error);
        });
        tomSelectCollectorAndHandler(form);
        handleMessageButton(form, index);
        form.addEventListener('submit', function (event) {
          event.preventDefault();
          event.stopPropagation();
          validateTomSelect(index);
          if (form.checkValidity()) {
            // Se il form è di tipo contactform esegui la funzione submitcontactform
            console.log(typeof form.getAttribute("data-form-type"));
            if(form.getAttribute("data-form-type") === "contactform"){
              submitContactForm(form, index).then((response) => {
                console.log(response);
                if (!response.error) {
                  showSuccessMessage(form);
                } else {
                  showErrorMessage(form, response.message);
                }
                makeLoading(false, form, index);
              }).catch(error => {
                //makeLoading(false, form, index);
                showErrorMessage(form, error.message);
                console.log(error.message);
              });
            } else if(form.getAttribute("data-form-type") === "jobform") {
              submitForm(form, index).then((response) => {
              console.log(response);
              if (!response.error) {
                showSuccessMessage(form);
              } else {
                showErrorMessage(form, response.message);
              }
              makeLoading(false, form, index);
            }).catch(error => {
              //makeLoading(false, form, index);
              showErrorMessage(form, error.message);
              console.log(error.message);
            });
            }
            
          }
          form.classList.add('was-validated');
        }, false);
      });
};

export function Form() {
  const forms = document.querySelectorAll('form.needs-validation');
  if (forms.length) {
    tomSelectHandler();
    formSubmitAndValidationHandler(forms);
  }
}



