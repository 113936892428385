import {gsap} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import responsiveMediaMatch from "../utils/responsiveMediaMatch";
gsap.registerPlugin(ScrollTrigger);

export function SocialLinkPinned() {
  const socialLink = document.querySelector('.social-fixed-link');
  window.addEventListener('load', function () {
    if (socialLink && responsiveMediaMatch.detectIsDesktop()) {
      ScrollTrigger.create({
        trigger: socialLink,
        start: 'top center',
        endTrigger: '.footer',
        end: 'bottom bottom',
        pin: true,
        pinType: 'fixed',
        scrub: true,
        pinSpacing: false,
        // pinType: "fixed",
        markers: false,
        toggleClass: 'active',
        onEnterBack: (st) => {
          st.pin.classList.remove('d-lg-none');
          //st.pin.classList.add('d-lg-block');
        },
        onLeave: (st) => {
          st.pin.classList.add('d-lg-none');
        }
      });
    }
  });
}