import {gsap} from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

// Detect if a link's href goes to the current page

function getSamePageAnchor(link) {
  if (
      link.protocol !== window.location.protocol ||
      link.host !== window.location.host ||
      link.pathname !== window.location.pathname ||
      link.search !== window.location.search
  ) {
    return false;
  }

  return link.hash;
}

// Scroll to a given hash, preventing the event given if there is one
function scrollToHash(hash, e) {
  const header = document.querySelector('.main-header>.navbar');
  const scroller = document.querySelector('.nav-scroller');
  const elem = hash ? document.querySelector(hash) : false;
  if (elem) {
    if (e) e.preventDefault();
    let offset = header?.offsetHeight+scroller?.offsetHeight;
    gsap.to(window, {scrollTo: {y: elem, offsetY: offset}});
    // gsap.to(window, 1, {scrollTo:{y:elem, offsetY: () => {
    //       return header+scroller
    //     }}});
  }
}

function scrollToSection(section, e) {
  const header = document.querySelector('.main-header>.navbar');
  const scroller = document.querySelector('.nav-scroller');
  if (section) {
    if (e) e.preventDefault();
    let offset = header?.offsetHeight+scroller?.offsetHeight;
    gsap.to(window, {scrollTo: {y: section, offsetY: offset}});
    // gsap.to(window, 1, {scrollTo:{y:elem, offsetY: () => {
    //       return header+scroller
    //     }}});
  }
}


export function ScrollTo() {

  window.addEventListener('load', function () {
    // If a link's href is within the current page, scroll to it instead
    document.querySelectorAll('a[href]').forEach(a => {
      a.addEventListener('click', e => {
        scrollToHash(getSamePageAnchor(a), e);
      });
    });
  });

  document.querySelector('.scroll-down')?.addEventListener('click', function () {
    const sections = document.querySelectorAll('section');
    scrollToSection(sections[1]);
  });

  // Scroll to the element in the URL's hash on load
  scrollToHash(window.location.hash);
}
