export function VideoFullScreen() {
  const videoModal = document.querySelectorAll('.video-full-screen');
  if (videoModal) {
    videoModal.forEach(modal => {
      let video = modal.querySelector('video');
      modal.addEventListener('hidden.bs.modal', function () {
        video.pause();
      });
      modal.addEventListener('shown.bs.modal', function () {
        video.play();
      });
    });
  }
}
