import Swiper, {Autoplay, Grid, Pagination, Lazy} from 'swiper';

Swiper.use([Grid, Autoplay, Pagination, Lazy]);

const insights = document.querySelectorAll('.service-insights-swiper');
export function InsightSwiper() {
  const slidesPerViewConfig = {
      1 : 1,
      2: 2,
      3: 3
  };
  if (insights.length) {
    insights.forEach(insight => {
      const insightElem = insight.querySelectorAll('figure');
      new Swiper(insight, {
        spaceBetween: 15,
        autoplay: false,
        preloadImages: false,
        // Enable lazy loading
        lazy: true,
        slidesPerView: insightElem.length > 1 ? 1.2 : 1,
        breakpoints: {
          992: {
            slidesPerView: slidesPerViewConfig[insightElem.length]
          }
        }
      });
    });
  }
}
