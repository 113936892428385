import {gsap} from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import {Tab} from "bootstrap";

gsap.registerPlugin(ScrollTrigger);

const introAnimation = () => {
	const socialLink = document.querySelector('.social-fixed-link .social-link');
	const intro = document.querySelector('.security-page .security-intro');
  const circle = intro.querySelectorAll('.svg-intro svg g');
  // const cmgRibbon = document.querySelector('.svg-intro svg #cmg-ribbon');
  const cmgShield = intro.querySelector('.svg-intro svg #cmg-shield');
  // gsap.to(cmgRibbon,{repeat: -1, duration: 1, rotation: 360, transformOrigin:"center center"});

	socialLink.classList.add('d-none');

  gsap.fromTo(cmgShield, {scale: 0.8}, {
    yoyo: true,
    repeat: -1,
    duration: 1,
    scale: 1.1,
    transformOrigin: "center center"
  });

  circle.forEach((c, i) => {
    gsap.fromTo(c, {
      rotation: 0
    }, {
      rotation: 360,
      duration: 8 + i,
      repeat: -1,
      ease: "linear",
      transformOrigin: "center center"
    });
  });

	ScrollTrigger.create({
		trigger: intro,
		start: 'center center',
		end: 'center',
		markers: false,
		onEnterBack: () => {
			socialLink.classList.add('d-none');
		},
		onLeave: () => {
			socialLink.classList.remove('d-none');
		}
	});
};

const initTab = () => {
  let triggerTabList = [].slice.call(document.querySelectorAll('.nav-pills a'));
  triggerTabList.forEach(function (triggerEl) {
    let tabTrigger = new Tab(triggerEl);

    triggerEl.addEventListener('click', function (event) {
      event.preventDefault();
      tabTrigger.show();
    });
  });
};

export function SecurityPage() {
  if (document.querySelector('.security-page')) {
    initTab();
    introAnimation();
  }
}
